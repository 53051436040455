<template>
  <b-modal
      :id="metaData.formId"
      size="lg"
      centered
      hide-header
      hide-footer
      @hidden="onHidden"
  >
    <div class="header">
      <div class="header-content">
        <p>{{ form?.id ? `Atualizar` : 'Novo' }} Contrato</p>
        <Close class="close-svg" @click="$bvModal.hide(metaData.formId)" />
      </div>
    </div>
    <div class="body">

      <b-row v-if="clinics" v-show="!invoice">
        <b-col xs="12">
          <b-form-group>
            <label for="clinic_id">Clínica</label>
            <multiselect
                class="with-border"
                v-model="clinic"
                :options="clinics"
                track-by="id"
                label="name"
                placeholder="Selecionar Clínica"
                :showLabels="false"
                :searchable="true"
                :allowEmpty="true"
                :preselect-first="false"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions"> Nenhuma opção </template>
              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="invoice">
        <b-col xs="12">
          <p class="text-center text-primary">
            {{ invoice.clinic_name }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12">
          <b-form-group>
            <label for="status">Status</label>
            <multiselect
                class="with-border"
                v-model="statusObj"
                :options="formatStatuses()"
                track-by="id"
                label="name"
                placeholder="Selecionar Status"
                :showLabels="false"
                :searchable="true"
                :allowEmpty="true"
                :preselect-first="false"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions"> Nenhuma opção </template>
              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12">
          <b-form-group>
            <label for="month_value">Mensalidade</label>
            <b-form-input
                id="month_value"
                autocomplete="off"
                v-model="form.month_value"
                type="number"
            />
            <div v-if="verified && !form.month_value" class="custom-invalid-feedback">Campo Mensalidade é Obrigatório</div>
          </b-form-group>
        </b-col>

        <b-col xs="12">
          <b-form-group>
            <label for="amount">Emissões de NF por Mês</label>
            <b-form-input
                id="amount"
                autocomplete="off"
                v-model="form.amount"
                type="number"
            />
            <div v-if="verified && !form.amount" class="custom-invalid-feedback">Campo Emissões de NF por Mês é Obrigatório</div>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col xs="12">
          <b-form-group>
            <label for="company_limit">Limite de CNPJs</label>
            <b-form-input
                id="company_limit"
                autocomplete="off"
                v-model="form.company_limit"
                type="number"
            />
            <div v-if="verified && !form.company_limit" class="custom-invalid-feedback">Campo Limite de CNPJs é Obrigatório</div>
          </b-form-group>
        </b-col>
        <b-col xs="12">
          <b-form-group>
            <label for="unity_value">Valor por Emissão Excedente</label>
            <b-form-input
                id="unity_value"
                autocomplete="off"
                v-model="form.unity_value"
                type="number"
            />
            <div v-if="verified && !form.unity_value" class="custom-invalid-feedback">Campo Valor por Emissão Excedente de NF por Mês é Obrigatório</div>
          </b-form-group>
        </b-col>
      </b-row>

      <div class="actions">
        <b-button
            variant="outline-secondary"
            @click="$bvModal.hide(metaData.formId)"
        >
          Cancelar
        </b-button>
        <b-button
            variant="primary"
            @click="form.id ? updateInvoiceContract() : saveInvoiceContract()"
        >
          Salvar
        </b-button>
      </div>
    </div>
  </b-modal>
</template>
<script>
import Close from '@/assets/icons/close.svg'
import ChevronDown from "@/assets/icons/chevron-down.svg";
import {mapState} from "vuex";

export default {
  name: 'InvoiceModalForm',
  components: {ChevronDown, Close },
  props: {
    invoice: Object
  },
  computed: {
    ...mapState({
      status: state => state.nfcontracts.status
    })
  },
  data() {
    return {
      verified: false,
      showClinics: true,
      form: {},
      clinics: null,
      clinic: null,
      statusObj: null,
      metaData: {
        formId: 'invoice-form-modal',
        entityLabel: 'Contrato Módulo NF',
      }
    }
  },
  mounted() {
    this.getAllClinics()
  },
  methods: {
    onHidden() {
      this.$emit('onHidden')
    },
    isValid() {
      this.verified = true
      return this.form.company_limit
    },
    async saveInvoiceContract() {
      if (!this.isValid()) return
      try {
        const l = this.initApiCall()
        await this.api.createNFContract(this.form)
        .then( res => {
          this.$toast.success(`${this.metaData.entityLabel} criado com sucesso`)
          this.$bvModal.hide(this.metaData.formId)
          this.$emit('refresh', res.data)
        })
        .finally( res => {
          l.hide()
        })
      } catch(err) {
        this.$toast.error(err.message)
      }
    },
    async updateInvoiceContract() {
      if (!this.isValid()) return
      try {

        const l = this.initApiCall()

        await this.api.updateNFContract(this.form)
        .then( res => {
          this.$toast.success(`${this.metaData.entityLabel} atualizado com sucesso`)
          this.$bvModal.hide(this.metaData.formId)
          this.$emit('refresh', res.data)
        })
        .finally( res => {
          l.hide()
        })
      } catch(err) {
        this.$toast.error(err.message)
      }
    },
    initApiCall() {
      this.form.clinic_id = this.clinic.id
      this.form.status = this.statusObj.id
      return this.$loading.show()
    },

    getAllClinics() {
      const loading = this.$loading.show()
      this.api
          .getAllClinics(true)
          .then(res => {
            this.clinics = res.data
          })
          .catch(err => {
            this.$toast.error(err.message)
          })
          .finally(() => {
            loading.hide()
          })
    },
    formatStatuses() {
      return Object.keys(this.status).map( (obj) => { return { name:this.status[obj].label, id: obj} })
    }
  },
  watch: {
    invoice(val) {
      this.form = val || {
        id: null,
        clinic_id: null,
        company_limit: null,
        month_value: null,
        unity_value: null,
        amount: null,
        status: null
      }

      this.clinic = val ? { id: val.clinic_id, name: val.clinic_name } : null
      this.statusObj = val ? { id: val.status, name: this.status[val.status].label } : null
    }
  }
}
</script>
<style lang="scss">
#invoice-form-modal {

  .modal-body {
    padding: 0;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: var(--type-active);

    .header {
      border-bottom: 1px solid var(--neutral-200);

      .header-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 24px;

        .close-svg {
          height: 24px;
          width: 24px;
          fill: var(--neutral-500);
        }
      }
    }
    .body {
      padding: 24px;
      color: var(--type-active);
    }
    .actions {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 10px;
      padding-top: 24px;
    }
  }

}
</style>
