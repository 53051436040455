<template>
  <div class="invoices-container">
    <div class="d-flex justify-content-between">
      <span class="title">
        Contratos Módulo Nota Fiscal
      </span>
    </div>

    <div class="invoices-table">
      <InvoicesTable @openModal="openModal" />
    </div>

    <InvoiceModalForm :invoice="invoice" @refresh="onRefresh" />
  </div>
</template>

<script>
import InvoicesTable from "@/components/Invoices/InvoicesTable.vue"
import InvoiceModalForm from "@/components/Invoices/InvoiceModalForm.vue"
import {EventBus} from "@/utils/eventBus"

export default {
  name: "Invoices",
  components: {InvoicesTable, InvoiceModalForm},
  data() {
    return {
      invoice: {}
    }
  },
  methods: {
    openModal(invoice) {
      this.invoice = invoice
      this.$bvModal.show('invoice-form-modal')
    },

    onRefresh(invoice) {
      if(invoice) {
        EventBus.$emit('refresh')
      }
    }
  }
}
</script>



<style scoped lang="scss">
.invoices-container {
  padding: 24px;
}
</style>