<template>
  <b-table-simple class="table" striped v-if="invoices">
    <thead>
    <tr>
      <b-td class="td-header">Clínica</b-td>
      <b-td class="td-header">Mensalidade</b-td>
      <b-td class="td-header">Valor por NF</b-td>
      <b-td class="td-header">NFs por Mês</b-td>
      <b-td class="td-header">CNPJs</b-td>
      <b-td class="td-header">Email Resp.</b-td>
      <b-td class="td-header">Status</b-td>
      <b-td>
        <div class="actions">
          <Icon tooltip="Adicionar Solicitação">
            <Plus class="icon-plus" @click="$emit('openModal', null)" />
          </Icon>
        </div>
      </b-td>
    </tr>
    </thead>
    <tbody>
    <b-tr v-for="invoice in invoices" :key="invoice.id" class="p-2">
      <b-td>{{ invoice.clinic_name }}</b-td>
      <b-td>{{ parseNumberToMoney(invoice.month_value) }}</b-td>
      <b-td>{{ parseNumberToMoney(invoice.unity_value) }}</b-td>
      <b-td>{{ invoice.amount }}</b-td>
      <b-td>{{ invoice.company_limit }}</b-td>
      <b-td>{{ invoice.clinic_email }}</b-td>
      <b-td v-if="status">
        <b-badge pill :variant="status[invoice.status].class">{{ status[invoice.status].label }}</b-badge>
      </b-td>
      <b-td>
        <div class="actions">
          <Icon tooltip="Editar Contrato">
            <Edit class="icon" @click="$emit('openModal', invoice)" />
          </Icon>
        </div>
      </b-td>
    </b-tr>
    </tbody>
  </b-table-simple>
</template>

<script>
import {parseNumberToMoney} from "@/utils/moneyHelper";

import Plus from "@/assets/icons/plus.svg";
import Icon from "@/components/General/Icon.vue";
import Edit from "@/assets/icons/edit.svg";
import {mapState} from "vuex";
import {EventBus} from "@/utils/eventBus"
export default {
  name: "InvoicesTable",
  components: {Edit, Icon, Plus},
  computed: {
    ...mapState({
      status: state => state.nfcontracts.status
    })
  },
  data() {
    return {
      invoices: null,
    }
  },
  mounted() {
    this.getNfContracts();
    EventBus.$on('refresh', () => {
      this.getNfContracts();
    })
  },

  destroyed() {
    EventBus.$off('refresh')
  },

  methods: {
    parseNumberToMoney,
    async getNfContracts() {
      const loading = this.$loading.show();

      await this.api.getNfContracts()
        .then( res => {
          this.invoices = res.data.data
        })
        .catch(err => {
          this.$toast.error(err.message)
        })
        .finally( () => {
          loading.hide()
        })
    }
  }
}
</script>

<style scoped lang="scss">
.table {
  .td-header {
    font-weight: 700;
    color: var(--active-type);
  }
  td {
    border-top: none;
    vertical-align: middle;
  }
  .full-width {
    width: 100%;
  }
}
.icon-plus {
  width: 24px;
  height: 24px;
  fill: var(--blue-700);
  cursor: pointer;
}
.actions {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;

  .icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}
</style>